<template>
  <el-row justify="center">
    <el-col :md="8">
      <el-card v-if="user" :header="`${user.firstName} ${user.lastName}`">
        <p>Email: {{ user.email }}</p>
        <p>Phone: {{ user.phone }}</p>
        <p>Advance payment balance: $ {{ user.advanceBalance }}</p>
        
        <el-button @click="logOut">Log Out</el-button>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import UserService from "../services/UserService"

export default {
  name: 'Profile',
  data() {
    return {
      user: null
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  },
  async mounted() {
    try {
      const response = await UserService.getMy()
      console.log(response.data)
      this.user = response.data
    } catch (e) {
      console.log(e)
    }
  }
};
</script>